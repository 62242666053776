/* global.css */
@import "sanitize.css";

#root {
  height: 100vh;
  line-height: 1.5;
  background-color: #262626;
}

body {
  font-family: "Pretendard", sans-serif;
  font-style: normal;
  line-height: 24px;
}
